<template>
  <fieldset :class="{ box: !isViewModeCreate }">
    <page-title
        :title="$t('page.companyName.title')"
        @edit="$emit('edit')"
    >
      <p>{{ $t('page.companyName.respectChart') }}</p>
      <p>{{ $t('page.companyName.termsRequired') }}</p>
      <ul>
        <li>{{ $t('page.companyName.exampleAccepted') }}</li>
        <li>{{ $t('page.companyName.exampleRefused1') }}</li>
        <li>{{ $t('page.companyName.exampleRefused2') }}</li>
      </ul>
      <p>{{ $t('page.companyName.ifNameRefused') }}</p>
      <professional-order-condition
          :order="storedModel.professionalOrder.order"
          page="companyName"
      >
      </professional-order-condition>
    </page-title>
    <b-field
        :label="getFieldLabel('frenchname')"
        :type="getFieldType('frenchname')"
        :message="getFieldMessage('frenchname')"
    >
      <b-input
          v-if="isFieldEditable('$.companyName.frenchname')"
          v-model="model.frenchname"
          @blur="validateFrenchname"
          custom-class="page-focus-first"
          autocomplete="off"
      >
      </b-input>
      <span v-else>{{ model.frenchname }}</span>
    </b-field>
    <revision-message path="$.companyName.frenchname"></revision-message>
    <b-field
        :label="getFieldLabel('englishname')"
        :type="getFieldType('englishname')"
        :message="getFieldMessage('englishname')"
    >
      <b-input
          v-if="isFieldEditable('$.companyName.englishname')"
          v-model="model.englishname"
          @blur="validateEnglishname"
          autocomplete="off"
      >
      </b-input>
      <span v-else>{{ model.englishname }}</span>
    </b-field>
    <revision-message path="$.companyName.englishname"></revision-message>
    <b-field
        :label="getFieldLabel('reasons')"
        :type="getFieldType('reasons')"
        :message="getFieldMessage('reasons')"
    >
      <b-input
          v-if="isFieldEditable('$.companyName.reasons')"
          v-model="model.reasons"
          maxlength="1000"
          type="textarea"
          @blur="validateReasons"
          autocomplete="off"
      >
      </b-input>
      <span v-else>{{ model.reasons }}</span>
    </b-field>
    <revision-message path="$.companyName.reasons"></revision-message>
    <b-field
        :label="getFieldLabel('activities')"
        :type="getFieldType('activities')"
        :message="getFieldMessage('activities')"
    >
      <b-input
          v-if="isFieldEditable('$.companyName.activities')"
          v-model="model.activities"
          maxlength="1000"
          type="textarea"
          @blur="validateActivities"
          autocomplete="off"
      >
      </b-input>
      <span v-else>{{ model.activities }}</span>
    </b-field>
    <revision-message path="$.companyName.activities"></revision-message>
    <b-field
        :label="getFieldLabel('territory')"
        :type="getFieldType('territory')"
        :message="getFieldMessage('territory')"
    >
      <b-input
          v-if="isFieldEditable('$.companyName.territory')"
          v-model="model.territory"
          maxlength="200"
          type="textarea"
          @blur="validateTerritory"
          autocomplete="off"
      >
      </b-input>
      <span v-else>{{ model.territory }}</span>
    </b-field>
    <revision-message path="$.companyName.territory"></revision-message>
  </fieldset>
</template>

<script>
  import page from '@/mixins/page'
  import ProfessionalOrderCondition from '../form/ProfessionalOrderCondition'

  export default {
    name: 'PageCompanyName',
    mixins: [
      page('companyName')
    ],
    components: {
      ProfessionalOrderCondition
    },
    methods: {
      validate () {
        this.clearErrors()

        this.validateActivities()
        this.validateEnglishname()
        this.validateFrenchname()
        this.validateReasons()
        this.validateTerritory()
      },
      validateActivities () {
        this.validateNotEmpty('activities', this.model.activities)
      },
      validateEnglishname () {
        if (this.model.englishname?.length > 0 && this.model.englishname?.length < 1)
          this.addError('englishname')
        else
          this.removeError('englishname')
      },
      validateFrenchname () {
        this.validateNotEmpty('frenchname', this.model.frenchname)
      },
      validateReasons () {
        this.validateNotEmpty('reasons', this.model.reasons)
      },
      validateTerritory () {
        this.validateNotEmpty('territory', this.model.territory)
      }
    }
  }
</script>
